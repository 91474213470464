/**
 * 레이아웃을 관리하는 store
 */

export default defineStore(
  'screenSearchStore',
  () => {
    // 검색 이력
    const searchHistroy = ref([]);

    return {
      searchHistroy,
    };
  },
  {
    persist: {
      storage: persistedState.localStorage,
      serializer: {
        serialize: JSON.stringify,
        deserialize: JSON.parse,
      },
    },
  },
);
