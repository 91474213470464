<template>
  <InputGroup class="custom-input-addon">
    <Dropdown
      ref="dropdown"
      v-model="selectedScreen"
      v-tooltip.bottom="'검색'"
      :options="formattedOptions"
      option-label="label"
      placeholder="메뉴명/메뉴번호 검색"
      class="w-full"
      editable
      auto-option-focus
      :virtual-scroller-options="{ itemSize: 20 }"
      show-clear
      @change="clear"
      @focus="showDropdownOptions"
      @input="handleInput"
      @keyup.enter="onKeyupEnter"
    />
    <InputGroupAddon>
      <Icon name="mdi:search" class="cursor-pointer text-white" size="1.5rem" @click="copyPanel()" />
    </InputGroupAddon>
  </InputGroup>
</template>

<script setup>
const { createPanel } = useWindowStore();
const screenSearchStore = useScreenSearchStore();
const { searchHistroy } = storeToRefs(screenSearchStore);
const screenList = useScreenList();
const uniqueList = ref(Array.from(new Set(screenList.value.map((item) => JSON.stringify(item)))).map((item) => JSON.parse(item)));

const selectedScreen = ref();
const inputText = ref('');
const dropdown = ref(null);
const isDropdownOpen = ref(false);

const copyPanel = () => {
  const menu = uniqueList.value.find(({ id }) => id === selectedScreen.value.id);
  if (menu) {
    createPanel(menu);
    updateSearchHistory(menu.id);
  }

  selectedScreen.value = null;
  inputText.value = '';
};

const onKeyupEnter = () => {
  copyPanel();
};

const filteredOptions = computed(() => {
  if (inputText.value) {
    return uniqueList.value.filter((item) => (item.title && item.title.includes(inputText.value)) || (item.id && item.id.includes(inputText.value)));
  } else {
    return searchHistroy.value;
  }
});

const formattedOptions = computed(() => {
  return filteredOptions.value.map((item) => ({
    ...item,
    label: `[ ${item.id} ] ${item.title}`,
  }));
});

const showDropdownOptions = async () => {
  if (!isDropdownOpen.value) {
    dropdown.value.show();
    isDropdownOpen.value = true;
  } else {
    dropdown.value.hide();
    isDropdownOpen.value = false;
    await nextTick();
    if (document.activeElement) {
      document.activeElement.blur();
    }
  }
};

const handleInput = (event) => {
  inputText.value = event.target.value;
};

const updateSearchHistory = (id) => {
  const index = searchHistroy.value.findIndex((item) => item.id === id);
  if (index !== -1) {
    const [item] = searchHistroy.value.splice(index, 1);
    searchHistroy.value.unshift(item);
  } else {
    const newItem = uniqueList.value.find((item) => item.id === id);
    if (newItem) {
      searchHistroy.value.unshift(newItem);
      if (searchHistroy.value.length > 10) {
        searchHistroy.value.pop();
      }
    }
  }
};

const clear = (event) => {
  if (selectedScreen.value?.id) {
    copyPanel();
    selectedScreen.value = null;
    inputText.value = '';
  }

  if (!event.value) {
    selectedScreen.value = null;
    inputText.value = '';
  }
};
</script>
<style scoped>
.custom-input-addon {
  height: 24px;
}
</style>
